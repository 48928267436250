import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { signOut } from 'supertokens-auth-react/recipe/emailpassword';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import SignUpInBtn from '../LoginPage/SignUpInBtn';
import Player from '../Player';

function Header() {
  const session = useSessionContext();
  const navigate = useNavigate();
  const pathname = useMatch('signin');
  const { t } = useTranslation();

  const onLogout = React.useCallback(async () => {
    await signOut();
    // reconnectSocket();
    navigate('/');
  }, []);

  const btn = React.useMemo(() => {
    if (pathname) {
      return null;
    }

    if (session.loading) {
      return null;
    }
    const { userId } = session;

    return !userId ? (
      <li>
        <SignUpInBtn type="signin" className="btn menu_icon-login" name="login" />
      </li>
    ) : (
      <li style={{ display: 'flex' }}>
        <button style={{ marginRight: '10px' }} className="btn menu_iconb" onClick={onLogout}>
          {t('logout')}
        </button>
        <Link to="/settings" className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
          <svg
            style={{ color: '#b3a063' }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-settings"
          >
            <circle cx="12" cy="12" r="3"></circle>
            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
          </svg>
        </Link>
      </li>
    );
  }, [session, pathname]);

  const pl = React.useMemo(() => {
    if (pathname) {
      return null;
    }

    if (session.loading) {
      return null;
    }
    const { userId } = session;

    return userId ? <Player /> : null;
  }, [session, pathname]);

  return (
    // <header className="header">
    //   <div className="container-fluid">
    //     <div className="row">
    //       <div className="col-3 logo_section">
    //         <div className="full">
    //           <div className="center-desk">
    //             <div className="logo">
    //               <Link to="/">
    //                 <img
    //                   width="100px"
    //                   className="img-fluid rounded"
    //                   src="/images/android-chrome-192x192.png"
    //                   alt="Phoenix logo"
    //                 />
    //               </Link>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-7 clearfix">
    //         <div className="float-end">{pl}</div>
    //       </div>
    //       <div className="col-2">
    //         <div className="right_header_info">
    //           <ul>{btn}</ul>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </header>
    <header className="main-header d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4">
      <Link to="/" className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
        <img width="100px" className="img-fluid rounded img-logo" src="/images/logo.svg" alt="Phoenix logo" />
      </Link>
      <div className="col-12 col-sm-auto ms-auto">{pl}</div>
      <div className="col-sm-auto text-end">
        {btn}
        <div className="d-flex flex-wrap flex-md-nowrap"></div>
      </div>
    </header>
  );
}

export default Header;
