import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export const CancelButton = () => {
  const { t } = useTranslation();
  return (
    <div className="d-grid gap-2">
      <NavLink className="btn cancel_btn" to="/">
        {t('cancel')}
      </NavLink>
    </div>
  );
};
