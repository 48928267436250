import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import 'react-alice-carousel/lib/alice-carousel.css';
import GameList from '../../components/GameList';
import GameListCarousel from '../../components/GameList/GameListCarousel';

function Home() {
  const { t } = useTranslation();
  const showTournament = false;
  const showGameListCarousel = false;
  return (
    <>
      {showTournament && (
        <section className="slider_section">
          <div className="homepage-margin"></div>
          <div className="banner_main mx-auto">
            <div className="centered-text">
              <div className="title-img">{t('tournament')}</div>
              <div className="date-img">{t('date')}</div>
            </div>
            <div className="container-fluid padding3">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-bg">
                    <Link className="btn" to="/play/1560c13f-4707-494d-922b-b49dd1dfacf1">
                      {t('playNow')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid padding3">
              <div className="row">
                <Link to="/tournament">
                  <p className="leaderboard-home">{t('leaderboard')}</p>
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}
      {showGameListCarousel ? <GameListCarousel params={{ all: 'true' }} /> : <GameList params={{ all: 'true' }} />}
      <div className="licens">
        <div className="container">
          <div className="row align-items-center justify-content-center ">
            <div className="col-12 ">
              <div className="titlepage">
                <h3>{t('licensingReglation')}</h3>
                <div className="row align-items-center justify-content-center">
                  <div className="col-md-6 col-12 align-items-center justify-content-center">
                    <img width="100px" className="img-lisence" src="/images/image_1.svg" alt="Phoenix logo" />
                  </div>
                  <div className="col-md-6 col-12 align-items-center justify-content-center">
                    <img width="100px" className="img-lisence" src="/images/image_2.svg" alt="Phoenix logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
