import React from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { initModals, selectModal } from './store';

type TModalButton = {
  fn: () => void;
  text: string;
} | null;

type TModal = {
  children: React.ReactNode;
  name: string;
  okBtn: TModalButton;
  cancelBtn: TModalButton;
};

function Modal(props: TModal) {
  const portalDiv = React.useRef<HTMLElement>(document.getElementById('modal'));
  const { t } = useTranslation();

  const modal = useAppSelector(selectModal);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const handleClick = (e: Event) => {
      if (portalDiv.current && portalDiv.current.contains(e.target as Node)) {
        e.preventDefault();
        e.stopPropagation();
        dispatch(initModals(props.name));
      }
    };

    if (modal[props.name]) {
      document.addEventListener('click', handleClick);
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      document.documentElement.style.position = 'relative';
      document.body.style.position = 'relative';
      document.documentElement.style.height = '100%';
      document.body.style.height = '100%';
      return () => {
        document.removeEventListener('click', handleClick);
        document.documentElement.style.overflow = 'auto';
        document.body.style.overflow = 'auto';
        document.documentElement.style.position = 'inherit';
        document.body.style.position = 'inherit';
        document.documentElement.style.height;
        document.body.style.height = '100%';
      };
    }
  }, [modal[props.name]]);

  // const cancelFn = React.useCallback(() => {
  //   if (props.cancelBtn) {
  //     props.cancelBtn.fn();
  //   }
  //   dispatch(initModals(props.name));
  // }, [props.name]);

  const okFn = React.useCallback(() => {
    if (props.okBtn) {
      props.okBtn.fn();
    }
    dispatch(initModals(props.name));
  }, [props.name]);

  if (!portalDiv.current || !modal[props.name]) {
    return null;
  }

  return createPortal(
    <div
      className="modal fade show d-block"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered ">
        <div className="modal-content  modal-background ">
          <div className="modal-header border-0">
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{ color: 'white !important' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 24 24"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid container-size">
              <div className="row">
                <div className="col-xs-4 ms-auto text-center text-tournament">{t('tournament')}</div>
              </div>
              <div className="row">
                <div className="col-xs-4 ms-auto text-center date-tournament">{t('date')}</div>
              </div>
            </div>
          </div>
          {/* <div className="modal-footer border-0 d-flex justify-content-center">
            <Link className="btn btn-primary btn-tournament text-date" to="/play/1560c13f-4707-494d-922b-b49dd1dfacf1">
              {t('playNow')}
            </Link>
          </div> */}

          <div className="modal-footer border-0 d-flex justify-content-center">
            {props.okBtn && (
              <button type="button" className="btn btn-primary btn-tournament text-date" onClick={okFn}>
                {t(props.okBtn.text)}
              </button>
            )}
            {/* {props.cancelBtn && (
              <button type="button" className="btn btn-primary" onClick={cancelFn}>
                {props.cancelBtn.text}
              </button>
            )} */}
          </div>
        </div>
      </div>
    </div>,
    portalDiv.current,
  );
}

export default React.memo(Modal);
