import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { sortWinner } from './help';
import { fetchTournament, selectTournament } from './store';

function WinnerList() {
  const { transactions, wallets } = useAppSelector(selectTournament);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchTournament());
    const id = setInterval(() => {
      dispatch(fetchTournament());
    }, 60000);
    return () => {
      clearInterval(id);
    };
  }, []);

  const dataTransactions = React.useMemo(() => sortWinner([...transactions], [...wallets]), [transactions]);

  return (
    <div id="game">
      <div className="container">
        <div className="row">
          <div className="titlepage">
            <h2>{t('leaderboard')}</h2>
          </div>
          {dataTransactions.map((item, index) => {
            const wallet = wallets.find(({ playerId }) => playerId === item.playerId)!;
            const fullName = wallet ? `${wallet['player'].name} ${wallet['player'].surname}` : '';
            const rowClass = index === 0 ? ' col-12 col-md-6 offset-md-3 mb-2' : 'col-12 col-md-6 mb-2';
            const leaderClass = index === 0 ? ' first-leader container-fluid' : ' other-leader container-fluid';
            return (
              <div key={item.playerId} className={rowClass}>
                <div className={leaderClass}>
                  <div className="row centered_text row_responsive">
                    <div className="col-12 col-s-2 h1 ">{'#' + (index + 1)}</div>
                    <div className="col-12 col-s-6">
                      <div className="row">
                        <div className="col-12  h5 text-s-start text-truncate">
                          <strong>{fullName}</strong>
                        </div>
                        <div className="col-12 h5 text-truncate text-s-start">{wallet?.player.company}</div>
                      </div>
                    </div>
                    <div className="col-12 col-s-4">
                      <div className="row">
                        <div className="col-12  h4 text-s-start fw-bolder align-items-center fs-5 win-amount">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-star-fill"
                            viewBox="0 0 16 16"
                            style={{ marginRight: '5px' }}
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                          {Intl.NumberFormat('en-US', {
                            compactDisplay: 'short',
                            notation: 'compact',
                            maximumFractionDigits: 2,
                            maximumSignificantDigits: 5,
                          }).format(item.amount)}
                        </div>
                        <div className=" col-12  h4 text-s-start  align-items-center fs-6 win-amount">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-database"
                            viewBox="0 0 16 16"
                            style={{ marginRight: '5px' }}
                          >
                            <path d="M4.318 2.687C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4c0-.374.356-.875 1.318-1.313ZM13 5.698V7c0 .374-.356.875-1.318 1.313C10.766 8.729 9.464 9 8 9s-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 5.698ZM14 4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16s3.022-.289 4.096-.777C13.125 14.755 14 14.007 14 13V4Zm-1 4.698V10c0 .374-.356.875-1.318 1.313C10.766 11.729 9.464 12 8 12s-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 8.698Zm0 3V13c0 .374-.356.875-1.318 1.313C10.766 14.729 9.464 15 8 15s-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13s3.022-.289 4.096-.777c.324-.147.633-.323.904-.525Z" />
                          </svg>
                          {Intl.NumberFormat('en-US', {
                            compactDisplay: 'short',
                            notation: 'compact',
                            maximumFractionDigits: 2,
                            maximumSignificantDigits: 5,
                          }).format(wallet.amount)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default WinnerList;
