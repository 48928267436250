import { IRequestMethod, type IFetchData, type IHttpClient, type IParseData, type TFetchError } from './types';

const defaultHeaders = {
  'accept': 'application/json',
  'content-type': 'application/json',
};

const parseData: IParseData = <Type>(value: unknown) => {
  if (typeof value === 'object' && value) {
    return value as Type;
  }
  throw Error('no parse data');
};

const fetchData: IFetchData = async (url, method, body, headers) => {
  const options: RequestInit = {
    method: method,
    headers: headers
      ? new Headers({
          ...defaultHeaders,
          ...headers,
        })
      : new Headers(defaultHeaders),
    redirect: 'error',
  };

  if (body) {
    Object.assign(options, { body: JSON.stringify(body) });
  }

  const res = await fetch(url, options);
  const { ok, statusText, status } = res;

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const data = await res.json();
    if (!ok) {
      const { message } = data as TFetchError;
      const err = new Error(message);
      err.name = status.toString();
      throw err;
    }
    return await parseData(data);
  } catch (error) {
    if (ok) {
      return undefined as never;
    }
    const err = new Error(statusText);
    err.name = status.toString();
    throw error;
  }
};

const httpClient: IHttpClient = {
  get: async (url, headers) => fetchData(url, IRequestMethod.GET, undefined, headers),
  post: async (url, body, headers) => fetchData(url, IRequestMethod.POST, body, headers),
  put: async (url, body, headers) => fetchData(url, IRequestMethod.PUT, body, headers),
  patch: async (url, body, headers) => fetchData(url, IRequestMethod.PATCH, body, headers),
  delete: async (url, headers) => fetchData(url, IRequestMethod.DELETE, undefined, headers),
};

export { httpClient };
