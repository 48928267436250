import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { fetchCancelFRB, fetchListCreatedFRB, fetchRemoveFRB } from './store';

function CancelFRB() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { list, createdList } = useAppSelector((state) => {
    return {
      createdList: state.frb.createdList,
      list: state.frb.frbList,
    };
  });

  React.useEffect(() => {
    dispatch(fetchListCreatedFRB());
  }, []);

  const slotName = React.useCallback(
    (id: string) => {
      const slot = list.find((i) => i.gameId === id);
      if (slot) {
        return slot.gameName;
      }
      return id;
    },
    [list.length],
  );

  const onClickCancelFRB = React.useCallback((id: string) => {
    dispatch(fetchCancelFRB(id));
  }, []);

  const onClickRemoveFRB = React.useCallback((id: string) => {
    dispatch(fetchRemoveFRB(id));
  }, []);

  return (
    <div className="mb-8 col-md-8 offset-md-2">
      <table className="table table-dark table-borderless table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">name</th>
            <th scope="col">rounds</th>
            <th scope="col">realAmount</th>
            <th scope="col">coinAmount</th>
            <th scope="col">coinValue</th>
            <th scope="col">startTime</th>
            <th scope="col">expiredAt</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {createdList.map((w, i) => (
            <tr key={i}>
              <th scope="row">{i + 1}</th>
              <td>{slotName(w.gameId)}</td>
              <td>{w.rounds}</td>
              <td>{w.realAmount}</td>
              <td>{w.coinAmount}</td>
              <td>{w.coinValue}</td>
              <td>{w.startTime}</td>
              <td>{w.expiredAt}</td>
              <td>
                <div className="input-group input-group-sm">
                  <button
                    onClick={() => onClickCancelFRB(w.externalBonusId)}
                    className="btn btn-outline-secondary"
                    type="button"
                  >
                    {t('cancel')}
                  </button>
                  <button
                    onClick={() => onClickRemoveFRB(w.externalBonusId)}
                    className="btn btn-outline-warning"
                    type="button"
                  >
                    {t('remove')}
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CancelFRB;
