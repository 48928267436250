export const enum IRequestMethod {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH',
}
export type THeaders = { [k in string]: string };

export type TBody = { [k in string]: unknown };

export type TFetchError = {
  message: string;
};

export type TUnd<T> = Exclude<T, undefined>;

export interface IHttpClient {
  get<Type>(url: string, headers?: THeaders): Promise<TUnd<Type>>;
  post<Type>(url: string, body: TBody, headers?: THeaders): Promise<TUnd<Type>>;
  patch<Type>(url: string, body: TBody, headers?: THeaders): Promise<TUnd<Type>>;
  put<Type>(url: string, body: TBody, headers?: THeaders): Promise<TUnd<Type>>;
  delete<Type>(url: string, headers?: THeaders): Promise<TUnd<Type>>;
}

export interface IFetchData {
  <Type>(url: string, method: IRequestMethod, body?: TBody, headers?: THeaders): Promise<TUnd<Type>>;
}

export interface IParseData {
  <Type>(data: unknown): Type & typeof Object;
}
