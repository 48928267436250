import React from 'react';
import useWebSocket from 'react-use-websocket';
import Session from 'supertokens-auth-react/recipe/session';
import { TJsonSocket, TSocketCb } from './types';

const getUrl = () => {
  const { protocol, host } = window.location;
  const wsProtocol = protocol === 'http:' ? 'ws:' : 'wss:';
  const url = `${wsProtocol}//${host}/api/ws`;
  return new URL(url);
};

export const useSocketClient = () => {
  const [socketUrl, setSocketUrl] = React.useState<string>(getUrl().toString());
  const listeners = React.useRef(new Map<string, TSocketCb>());
  const { sendMessage, lastJsonMessage } = useWebSocket<TJsonSocket>(socketUrl, {
    onOpen: () => sendMessage('hello'),
    onError: (e) => {
      console.log(e, 'Socket error');
    },
    reconnectInterval: 1500,
    retryOnError: true,
    heartbeat: {
      message: 'ping',
      returnMessage: 'pong',
      timeout: 5000,
      interval: 5000,
    },
  });

  const addListener = (name: string, cb: TSocketCb) => {
    listeners.current.set(name, cb);
  };

  const reconnectSocket = React.useCallback(async () => {
    const token = await Session.getAccessToken();
    const newUrl = getUrl();
    if (token) {
      newUrl.searchParams.set('token', token);
    }
    setSocketUrl(newUrl.toString());
  }, []);

  React.useEffect(() => {
    reconnectSocket();
  }, []);

  React.useEffect(() => {
    if (lastJsonMessage) {
      try {
        const { name, value } = lastJsonMessage;
        const cb = listeners.current.get(name);
        if (cb) {
          cb(value);
        }
      } catch (error) {
        console.error('socket error', error);
      }
    }
  }, [lastJsonMessage, listeners.current.size]);

  return { addListener, reconnectSocket };
};
