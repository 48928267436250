import { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';

interface DocumentWithFullscreen extends HTMLDocument {
  webkitFullscreenElement?: Element;
  webkitExitFullscreen?: () => void;
}

interface HTMLElementWithFullscreen extends HTMLElement {
  webkitRequestFullscreen?: () => void;
}

export function isFullScreen(el: React.RefObject<Element> | null) {
  const doc = document as DocumentWithFullscreen;
  const fsElement = doc.fullscreenElement ?? doc.webkitFullscreenElement;

  if (el && el.current) {
    return Boolean(fsElement === el.current);
  }

  return Boolean(fsElement);
}

export function useFullScreen<T extends HTMLElementWithFullscreen>(
  el: React.RefObject<T> | null = null,
): [boolean, () => void] {
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    const handleChange = () => {
      setFullScreen(isFullScreen(el));
    };

    document.addEventListener('webkitfullscreenchange', handleChange);
    document.addEventListener('fullscreenchange', handleChange);
    handleChange();
    return () => {
      document.removeEventListener('webkitfullscreenchange', handleChange);
      document.removeEventListener('fullscreenchange', handleChange);
    };
  }, [el]);

  const toggle = useCallback(() => {
    if (fullScreen) {
      const doc = document as DocumentWithFullscreen;
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen();
      }
    }

    const target: HTMLElementWithFullscreen = (el && el.current) ?? document.documentElement;
    if (target.requestFullscreen) {
      target.requestFullscreen().catch(console.error);
    } else if (target.webkitRequestFullscreen) {
      target.webkitRequestFullscreen();
    }
  }, [fullScreen, el]);

  return [fullScreen, toggle];
}

function debounce(fn: () => void, ms: number) {
  if (ms === 0) return fn;

  let timer: undefined | number;
  return () => {
    clearTimeout(timer);
    timer = window.setTimeout(() => {
      timer = undefined;
      fn();
    }, ms);
  };
}

export function useWindowSize(debounce_ms = 0) {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = debounce(() => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, debounce_ms);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [debounce_ms]);

  return size;
}

export const useTimer = (time: string) => {
  const [timer, setTimer] = useState<string | null>(null);

  useEffect(() => {
    if (time) {
      const endTime = DateTime.fromISO(time).plus({ minutes: 5 });
      const id = setInterval(() => {
        const { minutes, seconds } = endTime.diff(DateTime.now(), ['minutes', 'seconds']).toObject();
        if (seconds && seconds <= 0) {
          clearInterval(id);
          return;
        }
        const s = Math.floor(seconds ?? 0);
        const second = s < 10 ? `0${s}` : s;
        setTimer(`0${minutes}:${second}`);
      }, 1000);

      return () => clearInterval(id);
    }
  }, [time]);

  return { timer };
};
