import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(
    resourcesToBackend((language: string) => {
      return import(`./resources.${language}.json`);
    }),
  )
  .init({
    fallbackLng: 'en',
    preload: ['en'],
    load: 'languageOnly',
  });
export default i18n;
