import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from '../../lib/fetch';
import { fetchWalletMainCurrency, fetchWalletRefill } from '../Config/store';
import { IPlayer, IStorePlayer, ITournamentInfo, IWallet } from './types';

export const fetchMe = createAsyncThunk('player/me', async () => {
  const player = await httpClient.get<IPlayer>('/api/player/me');
  return player;
});

export const updateProfile = createAsyncThunk('player/wallet', async (currency: string) => {
  const wallets = await httpClient.post<IWallet[]>('/api/player/wallet', {
    currency,
  });
  return wallets;
});

const initialState: IStorePlayer = {
  id: '',
  name: '',
  email: '',
  company: '',
  surname: '',
  tournamentFinished: false,
  tournamentStartTime: '',
  currency: '',
  wallets: [],
  status: 'idle',
};

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    changeWallet(state, action: PayloadAction<IWallet>) {
      const w = state.wallets.filter((i) => i.type !== action.payload.type);
      return {
        ...state,
        wallets: [...w, action.payload],
      };
    },
    changeTournamentInfo(state, action: PayloadAction<ITournamentInfo>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMe.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMe.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          status: 'succeeded',
        };
      })
      .addCase(fetchMe.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateProfile.fulfilled, (state, action: PayloadAction<IWallet[]>) => {
        return {
          ...state,
          wallets: action.payload,
        };
      })
      .addCase(fetchWalletRefill.fulfilled, (state, { payload }) => {
        const wallets = state.wallets.map((item) => {
          if (item.currency === payload.currency && item.type === payload.type) {
            return {
              ...item,
              amount: +payload.amount,
            };
          }
          return item;
        });
        return {
          ...state,
          wallets: wallets,
        };
      })
      .addCase(fetchWalletMainCurrency.fulfilled, (state, { payload }) => {
        state.currency = payload.currency;
      });
  },
});

export const { changeWallet, changeTournamentInfo } = playerSlice.actions;

export default playerSlice.reducer;
