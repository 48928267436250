import React from 'react';
import WinnerList from '../../components/WinnerList';

function Tournament() {
  return (
    <div>
      <div className="banner_main">
        <div className="container-fluid padding3">
          <div className="row">
            {/* <img className="img-fluid mx-auto" src="/images/banner.jpg" alt="#" /> */}
            <div className="col-md-12"></div>
          </div>
        </div>
      </div>
      <WinnerList />
    </div>
  );
}

export default Tournament;
