import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import Session from 'supertokens-auth-react/recipe/session';
import i18n from './i18next';

export const SuperTokensConfig = {
  languageTranslations: {
    translationFunc: i18n.t.bind(i18n),
  },
  appInfo: {
    appName: 'Demo Casino FE',
    apiDomain: window.location.origin,
    websiteDomain: window.location.origin,
    apiBasePath: '/api/auth',
    websiteBasePath: '/signin',
  },
  // recipeList contains all the modules that you want to
  // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
  recipeList: [
    EmailPassword.init({
      useShadowDom: false,
      style: `
      [#supertokens-root]{
        background-color: black;
      }
      [data-supertokens~=container] {
        font-family: Arial, sans-serif;
    }
    [data-supertokens~=container #supertokens-root ] {
      background-color: #000;
  }
                [data-supertokens]{
                  background-color: #000;
                }
                [data-supertokens] {
                  background-color: #000;
                 
                  
                }
              [data-supertokens="label"]{
                color: white;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
              }
                 [data-supertokens~=headerTitle] {
                  color: white;
               }
               [data-supertokens~=button] {
                background-color: #B3A063;
                width:85px;
                height:38px;
                border: none;
                display: block;
                margin-left: auto;
                margin-right: auto;
             }
             [data-supertokens~=divider] {
                  display: none;
           }
           [data-supertokens~="input"] {
            background-color: white;
            touch-action: manipulatio  !important;
            zoom : 1 !important;
     }
     [data-supertokens~="secondaryText"]{
      color: white;
     }
     [data-supertokens~="superTokensBranding"]{
      display: none;
     }
     [data-supertokens~="forgotPasswordLink"]{
      color: white;
      text-decoration: underline;
     }
     [data-supertokens="heavyArrowLeftIcon] {
      background-color: white;
     }
     [data-supertokens~="label"]{
      color: white !important;
     }
     [data-supertokens~="formRow"]:last-of-type {
      padding: 0 !important;
  }
     [#supertokens-root ]{
      background: black;
     }
                 [data-supertokens~=label] {
                    border: inherit;
                    background-color: transparent;
                    font-size: 17px;
                    border-radius: inherit;
                    padding-bottom: 10px;
                    color: #f9fcfd;
                    width: 100%;
                 }
            `,
      getRedirectionURL: async (context) => {
        if (context.action === 'SUCCESS') {
          if (context.isNewUser) {
            return '/profile';
          }
          if (context.redirectToPath) {
            return context.redirectToPath;
          }
          return '/play';
        }
        return '/';
      },
      signInAndUpFeature: {
        signUpForm: {
          formFields: [
            {
              id: 'name',
              label: 'Your name',
              placeholder: 'Name',
              validate: (v) => {
                const value = String(v);
                if (value.length) {
                  return Promise.resolve(undefined);
                }
                return Promise.resolve('Name not valid');
              },
            },
            {
              id: 'surname',
              label: 'Your surname',
              placeholder: 'Surname',
              validate: (v) => {
                const value = String(v);
                if (value.length) {
                  return Promise.resolve(undefined);
                }
                return Promise.resolve('Surname not valid');
              },
            },
            {
              id: 'company',
              label: 'Your company',
              placeholder: 'Company',
              validate: (v) => {
                const value = String(v);
                if (value.length) {
                  return Promise.resolve(undefined);
                }
                return Promise.resolve('Company not valid');
              },
            },
          ],
        },
      },
    }),
    Session.init(),
  ],
};

export const PreBuiltUIList = [EmailPasswordPreBuiltUI];
