export interface IConfig {
  code: string;
  language: string;
  country: string;
  currency: string;
}

export const PLAYER_CONFIG: IConfig[] = [
  { code: 'EN-US', language: 'English', country: 'United States', currency: 'USD' },
  { code: 'CS-CZ', language: 'Czech', country: 'The Czech Republic', currency: 'CZK' },
  { code: 'PL-PL', language: 'Polish', country: 'Poland', currency: 'PLN' },
  { code: 'TR-TR', language: 'Turkish', country: 'Turkey', currency: 'TRY' },
  { code: 'PT-BR', language: 'Portuguese', country: 'Brazil', currency: 'BRL' },
  { code: 'HI-IN', language: 'Hindi', country: 'India', currency: 'INR' },
  { code: 'PT-PT', language: 'Portuguese', country: 'Portugal', currency: 'EUR' },
  { code: 'DE-DE', language: 'German', country: 'Germany', currency: 'EUR' },
  { code: 'BN-BD', language: 'Bengali', country: 'Bangladesh', currency: 'BDT' },
  { code: 'UK-UA', language: 'Ukrainian', country: 'Ukraine', currency: 'UAH' },
  { code: 'UZ-UZ', language: 'Uzbek', country: 'Uzbekistan', currency: 'UZS' },
  { code: 'UR-PK', language: 'Urdu', country: 'Pakistan', currency: 'PKR' },
  { code: 'NE-NP', language: 'Nepali', country: 'Nepal', currency: 'NPR' },
  { code: 'KK-KZ', language: 'Kazakh', country: 'Kazakhstan', currency: 'KZT' },
  { code: 'SI-LK', language: 'Sinhala', country: 'Sri Lanka', currency: 'LKR' },
  // { code: 'TA', language: 'Tamil', country: 'Sri Lanka', currency: 'LKR' },
  { code: 'AR-MA', language: 'Arabic', country: 'Morocco', currency: 'MAD' },
  { code: 'AR-EG', language: 'Arabic', country: 'Egypt', currency: 'EGP' },
  { code: 'NB-NO', language: 'Norwegian', country: 'Norway', currency: 'NOK' },
  { code: 'TL-PH', language: 'Tagalog', country: 'Philippines', currency: 'PHP' },
  { code: 'ES-CL', language: 'Spanish', country: 'Chile', currency: 'CLP' },
  { code: 'ES-PE', language: 'Spanish', country: 'Peru', currency: 'PEN' },
  { code: 'EN-CA', language: 'English', country: 'Canada', currency: 'CAD' },
  { code: 'FI-FI', language: 'Finnish', country: 'Finland', currency: 'EUR' },
  { code: 'EN-AU', language: 'English', country: 'Australia', currency: 'AUD' },
  { code: 'AZ-AZ', language: 'Azerbaijani', country: 'Azerbaijan', currency: 'AZN' },
  { code: 'AR-AE', language: 'Arabic', country: 'UAE', currency: 'AED' },
  { code: 'AR-KW', language: 'Arabic', country: 'Kuwait', currency: 'KWD' },
  { code: 'AR-SA', language: 'Arabic', country: 'Saudi Arabia', currency: 'SAR' },
  { code: 'VI-VN', language: 'Vietnamese', country: 'Vietnam', currency: 'VND' },
  { code: 'IT-IT', language: 'Italian', country: 'Italy', currency: 'EUR' },
  { code: 'EL-GR', language: 'Greek', country: 'Greece', currency: 'EUR' },
  { code: 'ES-ES', language: 'Spanish', country: 'Spain', currency: 'EUR' },
  { code: 'RU-RU', language: 'Russian', country: 'Russia', currency: 'RUB' },
  { code: 'ES-MX', language: 'Spanish', country: 'Mexico', currency: 'MXN' },
  { code: 'TH-TH', language: 'Thai', country: 'Thailand', currency: 'THB' },
  { code: 'EN-NZ', language: 'English', country: 'New Zealand', currency: 'NZD' },
  { code: 'FR-FR', language: 'French', country: 'France', currency: 'EUR' },
  { code: 'JA-JP', language: 'Japanese', country: 'Japan', currency: 'JPY' },
  { code: 'ZH-HANS', language: 'Simplified Chinese', country: 'China', currency: 'CNY' },
  { code: 'HY-AM', language: 'Armenian', country: 'Armenia', currency: 'AMD' },
  { code: 'KA-GE', language: 'Georgian', country: 'Georgia', currency: 'GEL' },
];

export const CURRENCY: string[] = [
  'USD',
  'EUR',
  'GBP',
  'INR',
  'AUD',
  'CAD',
  'SGD',
  'CHF',
  'MYR',
  'JPY',
  'CNY',
  'NZD',
  'THB',
  'HUF',
  'AED',
  'HKD',
  'MXN',
  'ZAR',
  'PHP',
  'SEK',
  'IDR',
  'BRL',
  'SAR',
  'TRY',
  'KRW',
  'EGP',
  'NOK',
  'KWD',
  'RUB',
  'DKK',
  'PKR',
  'PLN',
  'CLP',
  'TWD',
  'ARS',
  'CZK',
  'VND',
  'MAD',
  'LKR',
  'UAH',
  'NGN',
  'UGX',
  'RON',
  'BDT',
  'PEN',
  'GEL',
  'UZS',
  'BGN',
  'ISK',
  'NPR',
  'PYG',
  'MOP',
  'KZT',
  'AZN',
  'AMD',
];

export const sortConfig = (a: IConfig, b: IConfig) => {
  if (a.country > b.country) {
    return 1;
  }
  if (a.country < b.country) {
    return -1;
  }
  return 0;
};
