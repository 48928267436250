import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { httpClient } from '../../lib/fetch';
import { RootState } from '../../store';
import { IStoreTournament, ITournament } from './types';

export const fetchTournament = createAsyncThunk('tournament', async () => {
  const player = await httpClient.get<ITournament>('/api/tournament?limit=5');
  return player;
});

const initialState: IStoreTournament = {
  transactions: [],
  wallets: [],
  status: 'idle',
};

const tournamentSlice = createSlice({
  name: 'tournament',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTournament.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTournament.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          status: 'succeeded',
        };
      })
      .addCase(fetchTournament.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectTournament = (state: RootState) => state.tournament;

export default tournamentSlice.reducer;
