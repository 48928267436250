import React from 'react';
import { useWindowSize } from './hooks';

export const SafariIframe: React.FC<{
  url: string;
  name?: string;
}> = ({ url, name }) => {
  const size = useWindowSize(400);
  const [load, setLoad] = React.useState(false);

  return (
    <iframe
      sandbox="allow-same-origin allow-scripts allow-top-navigation allow-popups"
      onLoad={() => setLoad(true)}
      style={{
        width: `${size.width}px`,
        height: `${size.height}px`,
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 100,
        backgroundColor: 'black',
        visibility: load ? 'visible' : 'hidden',
        touchAction: 'none',
      }}
      name={name}
      title="game"
      src={url}
    />
  );
};
