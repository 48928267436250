import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from '../../lib/fetch';
import { TBody } from '../../lib/fetch/types';
import { IStoreConfig, IWallet } from './types';

export const fetchWallets = createAsyncThunk('player/wallet/list', async () => {
  const url = '/api/player/wallet/list';
  const wallets = await httpClient.get<IWallet[]>(url);
  return wallets;
});

export const fetchWalletRefill = createAsyncThunk('player/wallet/refill', async (data: IWallet) => {
  const url = '/api/player/wallet/refill';
  const wallet = await httpClient.patch<IWallet>(url, data as unknown as TBody);
  return wallet;
});

export const fetchWalletMainCurrency = createAsyncThunk('player/wallet/currency', async (currency: string) => {
  const url = '/api/player/wallet/currency';
  const data = await httpClient.patch<{ currency: string }>(url, { currency } as unknown as TBody);
  return data;
});

const initialState: IStoreConfig = {
  wallets: [],
  status: 'idle',
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    changeAmount(state, { payload }: PayloadAction<{ amount: string; index: number }>) {
      const wallets = state.wallets.map((item, index) => {
        if (index === payload.index) {
          return {
            ...item,
            amount: payload.amount,
          };
        }
        return item;
      });
      return {
        ...state,
        wallets,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchWallets.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWallets.fulfilled, (state, action) => {
        return {
          ...state,
          status: 'succeeded',
          wallets: action.payload,
        };
      })
      .addCase(fetchWallets.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { changeAmount } = configSlice.actions;

export default configSlice.reducer;
