import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { useAppDispatch, useAppSelector } from '../../store';
import Modal from '../Modal';
import { openModal } from '../Modal/store';
import { getGameThumbnail } from './GameListCarousel';
import { fetchGames, selectGames } from './store';
import { IGame, TParam } from './types';

type TGamesProps = {
  params?: TParam;
};

export default function GameList(props: TGamesProps) {
  const dispatch = useAppDispatch();
  const { games } = useAppSelector(selectGames);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const session = useSessionContext();

  React.useEffect(() => {
    if (props?.params || !games.length) {
      dispatch(fetchGames(props.params));
    }
  }, []);

  const onClick = React.useCallback((item: IGame) => {
    if (session.loading) {
      return;
    }
    if (item.tournament && session.userId) {
      return dispatch(openModal(item.gameName));
    } else {
      return navigate(`/play/${item.gameId}`);
    }
  }, []);

  const okButton = React.useCallback(
    (item: IGame) => {
      return navigate(`/play/${item.gameId}`);
    },
    [session.loading],
  );

  return (
    <div id="game" className="casino">
      <h1 className="game-title">{t('phoenixGames')}</h1>
      <div></div>
      <div className="container">
        <div className="row row-padding">
          {games.map((item) => (
            <React.Fragment key={item.gameId}>
              <Modal
                name={item.gameName}
                okBtn={{
                  fn: () => okButton(item),
                  text: 'playNow',
                }}
                cancelBtn={null}
              >
                <div>Modal Window</div>
              </Modal>
              <div key={item.gameId} className="col-md-4 padding_bottom">
                <div className={item.gameId === '1560c13f-4707-494d-922b-b49dd1dfacf1' ? '' : ''}>
                  <div className="tournament-game-header">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-trophy-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935z" />
                    </svg>
                    {t('tournament')}
                  </div>

                  <div className="game_box">
                    <figure>
                      <img src={getGameThumbnail(item)} alt="#" />
                    </figure>
                  </div>
                  <div className="game">
                    <h3>{item.gameName}</h3>
                  </div>
                  <div className="btn-toolbar justify-content-center" role="toolbar">
                    <div className="btn-group" role="group">
                      <Link className="btn-link demo" to={`/demo/${item.gameId}`}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-play-circle icon-margin"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                        </svg>
                        {t('demoPlay')}
                      </Link>
                      <button
                        className="btn-link play"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                        onClick={() => onClick(item)}
                        style={{ color: 'white' }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-database icon-margin"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.318 2.687C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4c0-.374.356-.875 1.318-1.313ZM13 5.698V7c0 .374-.356.875-1.318 1.313C10.766 8.729 9.464 9 8 9s-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 5.698ZM14 4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16s3.022-.289 4.096-.777C13.125 14.755 14 14.007 14 13V4Zm-1 4.698V10c0 .374-.356.875-1.318 1.313C10.766 11.729 9.464 12 8 12s-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 8.698Zm0 3V13c0 .374-.356.875-1.318 1.313C10.766 14.729 9.464 15 8 15s-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13s3.022-.289 4.096-.777c.324-.147.633-.323.904-.525Z" />
                        </svg>
                        {t('realPlay')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}
