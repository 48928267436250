import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { httpClient } from '../../lib/fetch';
import { RootState } from '../../store';
import { IGame, IStoreHome as IStoreGameList, TParam } from './types';

export const fetchGames = createAsyncThunk('games/get', async (params: TParam | undefined) => {
  let url = '/api/games/get';
  if (params) {
    url = url + '?' + new URLSearchParams(params).toString();
  }
  const games = await httpClient.get<IGame[]>(url);
  return games;
});

const initialState: IStoreGameList = {
  games: [],
  status: 'idle',
};

const gameSlice = createSlice({
  name: 'gameList',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchGames.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGames.fulfilled, (state, action) => {
        return {
          status: 'succeeded',
          games: action.payload,
        };
      })
      .addCase(fetchGames.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectGames = (state: RootState) => state.games;

export default gameSlice.reducer;
