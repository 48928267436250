import React, { useEffect } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import Footer from '../Footer';
import Header from '../Header';
import Loading from '../Loading';

type TTemplateProps = {
  children: React.ReactNode;
};

function Template(props: TTemplateProps) {
  const simpleDisplay = true;
  const sessionContext = useSessionContext();
  const isTournament = useMatch('/tournament');
  const isHomePage = useMatch('/');
  const location = useLocation();
  const backgroundClass = isTournament ? 'container tournament-bg' : isHomePage ? 'container home-bg' : 'container';

  useEffect(() => {
    if (location.pathname.startsWith('/play/') || location.pathname.startsWith('/demo/')) {
      setTimeout(() => {
        const section = document.querySelector('#game-frame');
        section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 1000);
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      });
    }
  }, [location.pathname]);
  if (sessionContext.loading === true) {
    return <Loading />;
  }

  return (
    <div role="main" className="mx-auto root">
      <div className={simpleDisplay ? 'container' : backgroundClass}>
        <Header />
        <div className="container-fluid children template">{props.children}</div>
      </div>
      <Footer />
    </div>
  );
}

export default Template;
