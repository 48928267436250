import React from 'react';
import { useParams } from 'react-router-dom';
import GameIframe from '../../components/GameIframe';

type TGameProps = {
  demo: boolean;
};

function Game(props: TGameProps) {
  const { gameId } = useParams<{ gameId: string }>();

  if (!gameId) {
    return null;
  }

  return <GameIframe demo={props.demo} gameId={gameId} />;
}

export default Game;
