import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  changeCoinAmount,
  changeCoinValue,
  changeExpiredAt,
  changeGame,
  changeMode,
  changeRealAmount,
  changeRounds,
  changeStartTime,
  fetchCreateFRB,
  fetchListFRB,
} from './store';

function CreateFRB() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { currency, list, mode, form } = useAppSelector((state) => {
    return {
      currency: state.player.currency,
      list: state.frb.frbList,
      mode: state.frb.mode,
      form: state.frb.createForm,
    };
  });

  React.useEffect(() => {
    if (currency) {
      dispatch(fetchListFRB(currency));
    }
  }, [currency]);

  const onChangeGame = React.useCallback(
    (slotId: string) => {
      const slot = list.find((i) => i.gameId === slotId);
      if (slot) {
        dispatch(changeGame({ freeBetBonusId: slot.freeBetBonusId, gameId: slot.gameId }));
      }
    },
    [list.length],
  );

  const onChangeMode = React.useCallback((mode: 'coinAmount' | 'realAmount') => {
    dispatch(changeMode(mode));
  }, []);

  const onChangeRounds = React.useCallback((n: string) => {
    if (!n) {
      dispatch(changeRounds(0));
    } else {
      dispatch(changeRounds(+n));
    }
  }, []);

  const onChangeStartTime = React.useCallback((n: string) => {
    dispatch(changeStartTime(n));
  }, []);

  const onChangeExpiredAt = React.useCallback((n: string) => {
    dispatch(changeExpiredAt(n));
  }, []);

  const onClickCreateFRB = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(
        fetchCreateFRB({
          currency,
          ...form,
        }),
      );
    },
    [form],
  );

  const { coinAmounts, coinValues, realAmount } = React.useMemo(() => {
    const slot = list.find((i) => i.gameId === form.gameId);
    if (slot) {
      const [{ coinAmounts, coinValues, bets }] = slot.betAmounts;
      return {
        coinAmounts,
        coinValues,
        realAmount: bets,
      };
    }
    return {
      coinAmounts: [],
      coinValues: [],
      realAmount: [],
    };
  }, [form, list.length]);

  const onChangeRealAmount = React.useCallback((coin: number) => {
    dispatch(changeRealAmount(coin));
  }, []);

  const onChangeCoinValue = React.useCallback((coin: number) => {
    dispatch(changeCoinValue(coin));
  }, []);

  const onChangeCoinAmount = React.useCallback((coin: number) => {
    dispatch(changeCoinAmount(coin));
  }, []);

  return (
    <div className="container">
      <Link to="/cancel-frb">go to cancel frb</Link>
      <form className="row g-3">
        <div className="mb-8 col-md-8 offset-md-2">
          <label className="form-label">{t('FRB slot')}</label>
          <select
            className="form-select"
            name="slot"
            onChange={(e) => {
              onChangeGame(e.target.value);
            }}
          >
            <option>*</option>
            {list.map((val) => {
              return (
                <option key={val.gameId} value={val.gameId}>
                  {val.gameName}
                </option>
              );
            })}
          </select>
        </div>
        <div className="mb-8 col-md-8 offset-md-2">
          <label className="form-label">{t('rounds')}</label>
          <input
            value={form.rounds ? form.rounds : ''}
            onChange={(e) => onChangeRounds(e.target.value)}
            type="number"
            className="form-control"
          />
        </div>
        <div className="mb-8 col-md-8 offset-md-2">
          <label className="form-label">{t('mode')}</label>
          <select
            className="form-select"
            name="mode"
            value={mode}
            onChange={(e) => {
              onChangeMode(e.target.value as 'coinAmount' | 'realAmount');
            }}
          >
            <option value="realAmount">real amount</option>
            <option value="coinAmount">coin amount</option>
          </select>
        </div>
        {form.gameId &&
          (mode === 'realAmount' ? (
            <div className="mb-8 col-md-8 offset-md-2">
              <label className="form-label">{t('realAmount')}</label>
              <select
                className="form-select"
                name="realAmount"
                onChange={(e) => {
                  onChangeRealAmount(+e.target.value);
                }}
              >
                <option>*</option>
                {realAmount.map((i) => (
                  <option value={i} key={i}>
                    {i}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <>
              <div className="mb-8 col-md-8 offset-md-2">
                <label className="form-label">{t('coinAmount')}</label>
                <select
                  className="form-select"
                  name="coinAmount"
                  onChange={(e) => {
                    onChangeCoinAmount(+e.target.value);
                  }}
                >
                  <option>*</option>
                  {coinAmounts.map((i) => (
                    <option value={i} key={i}>
                      {i}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-8 col-md-8 offset-md-2">
                <label className="form-label">{t('coinValue')}</label>
                <select
                  className="form-select"
                  name="coinValue"
                  onChange={(e) => {
                    onChangeCoinValue(+e.target.value);
                  }}
                >
                  <option>*</option>
                  {coinValues.map((i) => (
                    <option value={i} key={i}>
                      {i}
                    </option>
                  ))}
                </select>
              </div>
            </>
          ))}
        {form.bet && (
          <>
            <div className="mb-8 col-md-8 offset-md-2">
              <label className="form-label">{t('startTime')}</label>
              <input
                value={form.startTime}
                onChange={(e) => onChangeStartTime(e.target.value)}
                type="string"
                className="form-control"
              />
            </div>
            <div className="mb-8 col-md-8 offset-md-2">
              <label className="form-label">{t('expiredAt')}</label>
              <input
                value={form.expiredAt}
                onChange={(e) => onChangeExpiredAt(e.target.value)}
                type="string"
                className="form-control"
              />
            </div>
          </>
        )}
        <div className="col-12">
          <button type="submit" className="btn btn-primary" onClick={(e) => onClickCreateFRB(e)}>
            create FRB
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateFRB;
