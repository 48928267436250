import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { useSocketClient } from '../../lib/socket';
import { useAppDispatch, useAppSelector } from '../../store';
import { changeTournamentInfo, changeWallet, fetchMe } from './store';
import { ITournamentInfo, IWallet } from './types';

function Player() {
  const { wallet, name, load, currency } = useAppSelector((state) => {
    const wallet = state.player.wallets.find((i) => i.type === 'MAIN');
    const currency = state.player.currency;

    return {
      wallet,
      name: state.player.name,
      load: state.player.status !== 'succeeded',
      currency,
    };
  });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { addListener } = useSocketClient();

  React.useEffect(() => {
    dispatch(fetchMe());

    addListener('wallet', (v) => {
      dispatch(changeWallet(v as IWallet));
    });

    addListener('config', (v) => {
      dispatch(changeTournamentInfo(v as ITournamentInfo));
    });
  }, [currency]);

  const currencyFormat = React.useMemo(() => {
    if (wallet) {
      return new Intl.NumberFormat(undefined, { style: 'currency', currency: wallet.currency }).format(wallet.amount);
    }
    return '';
  }, [wallet]);

  if (load) {
    return null;
  }

  if (!wallet) {
    return <Navigate to="/profile" />;
  }

  return (
    <div className="player">
      <p className="nav-link text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="bi bi-person-circle mb-1 icon-style"
          viewBox="0 0 16 16"
        >
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
          <path
            fillRule="evenodd"
            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
          />
        </svg>
        {name}
      </p>
      <p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="bi bi-database  mb-1 icon-style"
          viewBox="0 0 16 16"
        >
          <path d="M4.318 2.687C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4c0-.374.356-.875 1.318-1.313ZM13 5.698V7c0 .374-.356.875-1.318 1.313C10.766 8.729 9.464 9 8 9s-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 5.698ZM14 4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16s3.022-.289 4.096-.777C13.125 14.755 14 14.007 14 13V4Zm-1 4.698V10c0 .374-.356.875-1.318 1.313C10.766 11.729 9.464 12 8 12s-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 8.698Zm0 3V13c0 .374-.356.875-1.318 1.313C10.766 14.729 9.464 15 8 15s-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13s3.022-.289 4.096-.777c.324-.147.633-.323.904-.525Z" />
        </svg>
        {t('balance')}:{currencyFormat}
      </p>
    </div>
  );
}

export default Player;
