import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { httpClient } from '../../lib/fetch';
import { ICancelFRB, ICreatedFRB, IFRB, IFRBForm, IStoreFRB, TBet } from './types';

export const fetchListFRB = createAsyncThunk('frb/list', async (currency: string) => {
  const frbList = await httpClient.get<IFRB[]>(`/api/frb/list?currencies=${currency}`);
  return frbList;
});

export const fetchCreateFRB = createAsyncThunk('frb/create', async (data: IFRBForm & { currency: string }) => {
  const frb = await httpClient.post<IFRB>('/api/frb/create', { ...data });
  return frb;
});

export const fetchListCreatedFRB = createAsyncThunk('frb/createdList', async () => {
  const frbList = await httpClient.get<ICreatedFRB[]>(`/api/frb/createdList`);
  return frbList;
});

export const fetchCancelFRB = createAsyncThunk('frb/cancel', async (id: string) => {
  const frb = await httpClient.post<ICancelFRB>('/api/frb/cancel', { externalBonusId: id });
  return frb;
});

export const fetchRemoveFRB = createAsyncThunk('frb/remove', async (id: string) => {
  const frb = await httpClient.post<ICancelFRB>('/api/frb/remove', { externalBonusId: id });
  return frb;
});

const initialState: IStoreFRB = {
  status: 'idle',
  frbList: [],
  createdList: [],
  mode: 'realAmount',
  createForm: {
    rounds: 0,
  } as IFRBForm,
};

const frbSlice = createSlice({
  name: 'frb',
  initialState,
  reducers: {
    changeGame(state, action: PayloadAction<{ gameId: string; freeBetBonusId: string }>) {
      const { gameId, freeBetBonusId } = action.payload;
      return {
        ...state,
        createForm: {
          gameId,
          freeBetBonusId,
          rounds: 0,
        } as IFRBForm,
      };
    },
    changeMode(state, action: PayloadAction<IStoreFRB['mode']>) {
      return {
        ...state,
        mode: action.payload,
      };
    },
    changeRounds(state, action: PayloadAction<number>) {
      return {
        ...state,
        createForm: {
          ...state.createForm,
          rounds: action.payload,
        },
      };
    },
    changeRealAmount(state, action: PayloadAction<number>) {
      const startTime = DateTime.now();
      const expiredAt = startTime.plus({ hour: 1 });
      return {
        ...state,
        createForm: {
          ...state.createForm,
          bet: {
            realAmount: action.payload,
          },
          startTime: startTime.toString(),
          expiredAt: expiredAt.toString(),
        },
      };
    },
    changeCoinAmount(state, action: PayloadAction<number>) {
      const startTime = DateTime.now();
      const expiredAt = startTime.plus({ hour: 1 });
      return {
        ...state,
        createForm: {
          ...state.createForm,
          bet: {
            coinAmount: action.payload,
          },
          startTime: startTime.toString(),
          expiredAt: expiredAt.toString(),
        },
      };
    },
    changeCoinValue(state, action: PayloadAction<number>) {
      return {
        ...state,
        createForm: {
          ...state.createForm,
          bet: {
            coinValue: action.payload,
          } as TBet,
        },
      };
    },
    changeStartTime(state, action: PayloadAction<string>) {
      return {
        ...state,
        createForm: {
          ...state.createForm,
          startTime: action.payload,
        },
      };
    },
    changeExpiredAt(state, action: PayloadAction<string>) {
      return {
        ...state,
        createForm: {
          ...state.createForm,
          expiredAt: action.payload,
        },
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchListFRB.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchListFRB.fulfilled, (state, action) => {
        return {
          ...state,
          frbList: action.payload,
          status: 'succeeded',
        };
      })
      .addCase(fetchListFRB.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchCreateFRB.fulfilled, (state, _action: PayloadAction<IFRB>) => {
        return {
          ...state,
          createForm: {
            rounds: 0,
          } as IFRBForm,
        };
      })
      .addCase(fetchListCreatedFRB.fulfilled, (state, action) => {
        return {
          ...state,
          createdList: action.payload,
        };
      })
      .addCase(fetchCancelFRB.fulfilled, (state, action) => {
        const createdList = state.createdList.filter((i) => i.externalBonusId !== action.payload.externalBonusId);
        return {
          ...state,
          createdList,
        };
      })
      .addCase(fetchRemoveFRB.fulfilled, (state, action) => {
        const createdList = state.createdList.filter((i) => i.externalBonusId !== action.payload.externalBonusId);
        return {
          ...state,
          createdList,
        };
      });
  },
});

export const {
  changeGame,
  changeMode,
  changeCoinAmount,
  changeCoinValue,
  changeRealAmount,
  changeRounds,
  changeStartTime,
  changeExpiredAt,
} = frbSlice.actions;

export default frbSlice.reducer;
