import React from 'react';
import { useTranslation } from 'react-i18next';

function Loading() {
  const { t } = useTranslation();
  return (
    <div className="loading d-flex justify-content-center">
      <div className="spinner-grow" role="status" style={{ width: 'auto', color: '#b3a063' }}>
        <span>{t('loading')}</span>
      </div>
    </div>
  );
}

export default Loading;
