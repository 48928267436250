import React from 'react';
import { useTranslation } from 'react-i18next';
import { redirectToAuth } from 'supertokens-auth-react';

type TSignUpInBtn = {
  type: 'signup' | 'signin';
  className: string;
  name: string;
};

function SignUpInBtn(props: TSignUpInBtn) {
  const { t } = useTranslation();

  const onClick = React.useCallback(
    () =>
      redirectToAuth({
        show: props.type,
        redirectBack: true,
      }),
    [props.type],
  );

  return (
    <button className={props.className} onClick={onClick}>
      {t(props.name)}
    </button>
  );
}

export default SignUpInBtn;
