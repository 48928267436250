import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { updateProfile } from '../../components/Player/store';
import { PLAYER_CONFIG, sortConfig } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../store';

function Profile() {
  const { forwardToPlay, load } = useAppSelector((state) => {
    return {
      forwardToPlay: !!state.player.wallets.length,
      load: state.player.status !== 'succeeded',
    };
  });
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [countryIndex, setCountryIndex] = React.useState<number>(0);
  const [currency, setCurrency] = React.useState('');

  const currencyList = React.useMemo(() => {
    return Array.from(
      new Set(
        PLAYER_CONFIG.map((val) => {
          return val.currency;
        }),
      ),
    );
  }, []);

  React.useEffect(() => {
    const lng = i18n.language.toLocaleUpperCase();
    const i = PLAYER_CONFIG.findIndex((i) => i.code === lng);
    if (i !== -1) {
      setCountryIndex(i);
    }
  }, []);

  React.useEffect(() => {
    const conf = PLAYER_CONFIG.at(countryIndex);
    if (conf) {
      i18n.changeLanguage(conf.code);
      setCurrency(conf.currency);
    }
  }, [countryIndex]);

  const submit = React.useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(updateProfile(currency));
    },
    [currency],
  );

  if (load) {
    return null;
  }

  if (forwardToPlay) {
    return <Navigate to="/play" />;
  }

  return (
    <div className="container">
      <div className="row">
        <form onSubmit={submit}>
          <div className="mb-3 col-md-4 offset-md-4">
            <label className="form-label">{t('labelCountry')}</label>
            <select
              value={countryIndex}
              className="form-select"
              name="country"
              onChange={(e) => setCountryIndex(+e.target.value)}
            >
              {PLAYER_CONFIG.sort(sortConfig).map((val, index) => {
                return (
                  <option key={val.country} value={index}>
                    {val.country} ({val.code})
                  </option>
                );
              })}
            </select>
            <label htmlFor="exampleInputEmail1" className="form-label">
              {t('labelCurrency')}
            </label>
            <select className="form-select" value={currency} onChange={(e) => setCurrency(e.target.value)}>
              {currencyList.map((val) => {
                return (
                  <option key={val} value={val}>
                    {val}
                  </option>
                );
              })}
            </select>
            <div className="invalid-feedback">Please, select currency</div>
          </div>
          <button type="submit" className="btn menu_iconb ">
            {t('submit')}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Profile;
