import { ITournamentWallet, IWinTransaction } from './types';

export const sortWinner = (transactions: IWinTransaction[], wallet: ITournamentWallet[]) => {
  if (!transactions.length || !wallet.length) {
    return [];
  }
  return transactions.sort((a, b) => {
    if (a.amount === b.amount) {
      const [walletA] = wallet.filter(({ playerId }) => playerId === a.playerId);
      const [walletB] = wallet.filter(({ playerId }) => playerId === b.playerId);
      return walletB.amount - walletA.amount;
    }
    return 0;
  });
};
