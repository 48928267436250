import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const initialState: Record<string, boolean> = {};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    initModals(state, action: PayloadAction<string>) {
      const name = action.payload;
      return {
        ...state,
        [name]: false,
      };
    },
    openModal(state, action: PayloadAction<string>) {
      const name = action.payload;
      return {
        ...state,
        [name]: true,
      };
    },
  },
});

export const { initModals, openModal } = modalSlice.actions;

export const selectModal = (state: RootState) => state.modal;

export default modalSlice.reducer;
