import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useMatch } from 'react-router-dom';

const NAVIGATE_MENU = [
  {
    name: 'Tournaments',
    to: '/tournament',
  },
  { name: 'Phoenix7.com', to: 'https://phoenix7.com/' },
  { name: 'Games', to: '/play' },
  { name: 'Leaderboard', to: '/tournament' },
  { name: 'Sign In/Sign Up', to: '/signin/?show=signin&redirectToPath=' },
  { name: 'Contact', to: '/' },
];

const NAVIGATE_LINKS = [
  {
    img: '/images/facebook.png',
    name: 'Facebook',
    to: 'https://www.facebook.com/Phoenix7Official',
  },
  { name: 'Instagram', to: 'https://www.instagram.com/phoenix7_igaming/', img: '/images/instagram.png' },
  {
    name: 'Twitter',
    to: 'https://twitter.com/i/flow/login?redirect_after_login=%2FPhoenix7_Gaming',
    img: '/images/twitter.png',
  },
  { name: 'LinkedIn', to: 'https://www.linkedin.com/company/phoenix7/mycompany/', img: '/images/linkedin.png' },
  { name: 'YouTube', to: 'https://www.youtube.com/channel/UCNMqfdSf4k7BNg3DsH1zqjA', img: '/images/youtube.png' },
];

const firstSection = NAVIGATE_MENU.slice(0, 3);

function Footer() {
  const { t } = useTranslation();
  const isTournament = useMatch('/tournament');
  const topBorder = isTournament ? { borderImage: 'none' } : {};
  const removePadding = isTournament ? { paddingTop: '2px' } : { paddingTop: '2px' };
  return (
    <footer>
      <div className="copyright">
        <div className="container">
          <div className="footer-text">
            <div className="titlepage game-title" style={removePadding}>
              <h4 style={topBorder}>{t('textMedia')}</h4>
            </div>

            <h5 className="text-follow">{t('textFollow')}</h5>
          </div>
          <div className="link-fotter">
            <ul className="Menu_footer link_footer">
              {NAVIGATE_LINKS.map(({ name, to, img }) => (
                <li key={name}>
                  <NavLink to={to}>
                    {' '}
                    <img width="32px" height="32px" className="rounded" src={img} alt={name} />
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-3">
              <div className="address">
                <Link to="/">
                  <img
                    width="258px"
                    className="img-fluid rounded img-logo-footer"
                    src="/images/logo.svg"
                    alt="Phoenix logo"
                  />
                </Link>
              </div>
            </div>
            <div className="col-12 col-sm-5 ms-auto">
              <div className="row">
                <div className="col-6">
                  <div className="address ">
                    <h3>{t('links')}</h3>
                    <div>
                      <ul className="col Menu_footer">
                        {firstSection.map(({ name, to }) => (
                          <li key={name}>
                            <NavLink to={to}>{t(name)}</NavLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="address">
                    <h3>{t('contactUs')}</h3>
                    <ul className="Links_footer">
                      <li>{t('contactUsText')}</li>
                      <li>{t('contactUsText2')}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
