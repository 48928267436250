import React, { useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import { fetchGames } from '../GameList/store';
import Loading from '../Loading';
import { useFullScreen, useTimer } from './hooks';
import { SafariIframe } from './Safari';
import { changeStatus, cleanUrl, fetchGame, selectGame } from './store';

type TGameIframeProps = {
  gameId: string;
  demo: boolean;
};

function GameIframe(props: TGameIframeProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const refIframe = React.useRef<HTMLIFrameElement | null>(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [, toggleHandle] = useFullScreen(refIframe);

  const {
    game: [game],
    url,
    tournamentStartTime,
    tournamentFinished,
  } = useAppSelector((state) => {
    const { url, status } = state.game;
    const { tournamentStartTime, tournamentFinished } = state.player;

    const games = state.games.games;

    const game = selectGame(props.gameId, games);

    return {
      game,
      url,
      status,
      tournamentStartTime,
      tournamentFinished,
    };
  });

  const { i18n, t } = useTranslation();

  const { timer } = useTimer(tournamentStartTime);

  React.useEffect(() => {
    if (game) {
      dispatch(
        fetchGame({
          params: {
            gameId: game.gameId,
            demo: props.demo,
            isMobile: false,
            // language: i18n.language.replace(/-.*/, ''),
            language: i18n.language,
            cashierUrl: window.location.origin + '/settings',
            lobbyUrl: window.location.origin + '/play',
          },
          tournament: game.tournament,
        }),
      );
      return () => {
        dispatch(cleanUrl());
      };
    } else {
      dispatch(fetchGames({ all: 'true' }));
    }
  }, [game]);

  React.useEffect(() => {
    return () => {
      dispatch(changeStatus('idle'));
    };
  }, [url, isIOS]);

  React.useEffect(() => {
    if (!props.demo && tournamentFinished && game?.tournament) {
      navigate('/play');
    }
  }, [tournamentFinished, game]);

  if (!url || !game) {
    const className = isMobile ? 'background-iframe' : 'ratio ratio-16x9 background-iframe';
    const style: React.CSSProperties | undefined = isMobile
      ? {
          height: '100vh',
          width: '100vw',
          background: 'black',
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          zIndex: 10000,
        }
      : undefined;
    return (
      <div>
        <div className={className} style={style} id="game-frame">
          <Loading />
        </div>
        <div>
          <Link to="/play">
            <p className="all-games"> {t('backToGames')}</p>
          </Link>
        </div>
      </div>
    );
  }

  if (isMobile) {
    return (
      <div
        className="background-iframe"
        style={{
          height: '100vh',
          width: '100vw',
          background: 'black',
          position: 'fixed',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          zIndex: '10000',
        }}
      >
        {game.tournament && <div className="timer">{timer}</div>}
        <SafariIframe url={url} name={game.gameName} />
      </div>
    );
  }

  return (
    <>
      <div>
        <div className="game-title">
          <h3>{game.gameName}</h3>
        </div>
        <div className="ratio ratio-16x9 background-iframe" id="game-frame" ref={refIframe}>
          {game.tournament && <div className="timer">{timer}</div>}
          <iframe
            title="game"
            name={game.gameName}
            src={url}
            key={props.gameId}
            sandbox="allow-same-origin allow-scripts allow-top-navigation allow-popups"
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: 'black',
              opacity: iframeLoaded ? 1 : 0,
              touchAction: 'none',
            }}
            allowFullScreen
            onLoad={() => {
              setIframeLoaded(true);
            }}
          />
        </div>
        <div>
          <button type="button" onClick={toggleHandle}>
            <p style={{ color: '#B3A063', background: 'black', paddingTop: '32px' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-fullscreen"
                viewBox="0 0 16 16"
                style={{ color: '#B3A063 !important', backgroundColor: 'black !important' }}
              >
                <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z" />
              </svg>
            </p>
          </button>
        </div>
        <div>
          <Link to="/play">
            <p className="all-games"> {t('backToGames')}</p>
          </Link>
        </div>
      </div>
    </>
  );
}

export default GameIframe;
