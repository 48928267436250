import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from '../../lib/fetch';
import { TBody } from '../../lib/fetch/types';
import { RootState } from '../../store';
import { TStatus } from '../../types';
import { ILaunchGame, IStoreGameIframe } from './types';

export const fetchGame = createAsyncThunk('games/launchGame', async ({ params }: ILaunchGame) => {
  const { url } = await httpClient.post<{ url: string }>('/api/games/launchGame', params as unknown as TBody);
  return url;
});

const initialState: IStoreGameIframe = {
  url: '',
  status: 'idle',
};

const gameIframeSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    changeStatus(state, action: PayloadAction<TStatus>) {
      return {
        ...state,
        status: action.payload,
      };
    },
    cleanUrl(state) {
      return {
        ...state,
        url: '',
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGame.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGame.fulfilled, (state, action) => {
        return {
          status: 'succeeded',
          url: action.payload,
        };
      })
      .addCase(fetchGame.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectGame = (id: string, state: RootState['games']['games']) => {
  return state.filter((item) => {
    return item.gameId === id;
  });
};

export const { changeStatus, cleanUrl } = gameIframeSlice.actions;

export default gameIframeSlice.reducer;
